<template>
  <div style="background-color: #fff;padding: 20px;">
    <div class="notice-title h1">{{ detailData.enterpriseName }}</div>
    <div class="h4 time"></div>
    <div>
      <div style="padding: 20px;min-height: 400px;">
        <img
          :src="detailData.pictureUrl"
          style="width: 50%;height: 300px;;object-fit: cover;float: right;margin: 20px;"
        />
        <div class="h1" style="line-height: 40px;" v-html="detailData.enterpriseInfo"></div>
      </div>
      <blue-tag title="企业介绍">
        <div>{{ detailData.enterpriseIntroduction }}</div>
      </blue-tag>
      <blue-tag title="创新成果">
        <div>{{ detailData.innovativeAchievements }}</div>
      </blue-tag>
      <blue-tag title="创新领域">
        <div>{{ detailData.innovativeField }}</div>
      </blue-tag>
      <blue-tag title="应用范围">
        <div>{{ detailData.applicationScope }}</div>
      </blue-tag>
      <blue-tag title="对外合作">
        <div>{{ detailData.foreignCooperation }}</div>
      </blue-tag>
    </div>
  </div>
</template>

<script>
import blueTag from './components/blue-tag-section-title.vue'

export default {
  name: 'Home',
  components: {
    blueTag
  },
  data() {
    return {
      notice_id: null,
      detailData: {},
    }
  },
  computed: {
  },
  created(options) {
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.businessEnterpriseDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  // text-align: center;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}
</style>
